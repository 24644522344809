import React, { useContext, useEffect, useState } from 'react';
import { Select, Icon } from 'antd';
import { ApiContext } from '../services/HubApi';
import PropTypes from 'prop-types';

const { Option } = Select;

export default function AccountChooser(props) {
    const api = useContext(ApiContext);

    const [ accounts, setAccounts ] = useState([]);

    useEffect(() => { 
        async function loadAccounts() {
            try {
                if ('reports' in props)
                {
                    const accountArray = await api.getAccountNamesForReports();
                    return accountArray;
                }

                if ('erpId' in props)
                {
                    const accountArray = await api.getAccountNamesForERP(props.erpId);
                    return accountArray;
                }
                
                const accountArray = await api.getAccountNames();
                return accountArray;
            }
            catch (error) {
                return [props.accountName];
            }
        }

        async function onLoad() {
            let accountIds = await loadAccounts();
            setAccounts(accountIds);
        }

        onLoad();

    }, [api, props]);

    useEffect(() => {
        return ()  => {
            //console.log("AccountChooser cleanup")
        };
    }, []);

    async function onChange(value) {
        for (let i = 0; i < accounts.length; ++i) {
            if (accounts[i].accountID === value) {
                props.setAccountDetails(accounts[i].accountID, accounts[i].accountName);
            }
        }
    }

    const options = [];

    for (let i = 0; i < accounts.length; ++i) {
        options.push(<Option key={i} value={accounts[i].accountID}><Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} /> {accounts[i].accountName}</Option>);
    }

    return (
        <Select value={props.accountID} onChange={onChange} disabled={props.disabled}>
            {options}
        </Select>
    );

}

AccountChooser.propTypes = {
    accountID: PropTypes.string,
    accountName: PropTypes.string,
    setAccountDetails: PropTypes.func,
    disabled: PropTypes.bool,
    erpId: PropTypes.string
};